import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const TodoList = defineAsyncComponent(async () => await import('./views/TodoList'))

return (_ctx: any,_cache: any) => {
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { namespace: "todo" }, {
    default: _withCtx(() => [
      _createVNode(_unref(TodoList))
    ]),
    _: 1
  }))
}
}

})